import React, { RefObject, useCallback, useEffect, useRef } from 'react'
import { SxProps } from '@mui/material'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll'
import { type BoxProps } from '../Box'
import { NavigationContainer, NavButton, StyledGridSlide, CarouselWrapper } from './styles'
import ChevronLeft from '../../icons/ChevronLeft'
import ChevronRight from '../../icons/ChevronRight'
import { styledComponent } from '../../styles/styled'

export const CarouselSlide = styledComponent(SplideSlide)({})
export const GridSlide = (props: BoxProps) => <StyledGridSlide {...props} />

export const CarouselLazyImage = ({
  src,
  alt,
  ...props
}: {
  src: string
  alt: string
  width?: string | number
  height?: string | number
  style?: React.CSSProperties
}) => <img data-splide-lazy={src} alt={alt} {...props} />

export const CarouselNavButton = ({
  onClick,
  type,
  refObj
}: {
  onClick: () => void
  type: 'next' | 'prev'
  refObj?: RefObject<HTMLButtonElement>
}) => (
  <NavButton ref={refObj} onClick={onClick} aria-label={`carousel-navigation-${type}`}>
    {type === 'next' && <ChevronRight width="7px" />}
    {type === 'prev' && <ChevronLeft width="7px" />}
  </NavButton>
)

interface CarouselProps {
  children: React.ReactNode
  slidesPerView: number
  breakpoints?: {
    [key: number]: {
      perPage: number
    }
  }
  navigation?: boolean
  navigationComponent?: React.ReactNode
  navigationPosition?: 'bottom' | 'laterally' | 'inside'
  pagination?: boolean
  spaceBetween?: number
  slidesPerGroup?: number
  loop?: boolean
  autoPlay?: boolean
  speed?: 'slower' | 'faster'
  sx?: SxProps
  updateOnMove?: boolean
  autoScroll?: boolean
  autoScrollInterval?: number
  vertical?: boolean
  currentSlide?: number
  verticalHeight?: number
  trimSpace?: boolean
  centerFocus?: boolean
  slidePadding?: number
  wheel?: boolean
  lazyLoad?: boolean
  reverseAutoScroll?: boolean
  disableDrag?: boolean
}

const Carousel = ({
  children,
  slidesPerView = 3,
  navigation = false,
  navigationPosition = 'laterally',
  pagination = false,
  spaceBetween,
  slidesPerGroup = 1,
  updateOnMove = false,
  autoScroll = false,
  loop = false,
  vertical = false,
  currentSlide = 0,
  verticalHeight,
  trimSpace = false,
  centerFocus = false,
  slidePadding = 0,
  wheel = false,
  lazyLoad = false,
  reverseAutoScroll = false,
  speed = 'faster',
  sx = {},
  breakpoints = {},
  disableDrag = false,
  navigationComponent,
  autoPlay,
  autoScrollInterval = 5000
}: CarouselProps) => {
  const splide = useRef<Splide>(null)
  const prevRef = useRef<HTMLButtonElement>(null)
  const nextRef = useRef<HTMLButtonElement>(null)
  const childCount = React.Children.count(children)

  const [isBeginning, setIsBeginning] = React.useState(true)
  const [isEnd, setIsEnd] = React.useState(false)

  useEffect(() => {
    let autoScrollIntervalId: NodeJS.Timeout

    if (splide.current) {
      splide.current.go(currentSlide)
      if (autoPlay) {
        autoScrollIntervalId = setInterval(() => {
          splide.current?.go('+1')
        }, autoScrollInterval)
      }
    }

    return () => {
      clearInterval(autoScrollIntervalId)
    }
  }, [currentSlide, splide])

  const handleNext = () => {
    console.log(splide.current)
    splide.current?.go('+1')
  }
  const handlePrev = () => splide.current?.go('-1')

  const handleDisableNavigation = useCallback(() => {
    if (prevRef.current && nextRef.current && !loop) {
      if (isBeginning) {
        prevRef.current.classList.add('Mui-disabled')
      } else {
        prevRef.current.classList.remove('Mui-disabled')
      }
      if (isEnd && !loop) {
        nextRef.current.classList.add('Mui-disabled')
      } else {
        nextRef.current.classList.remove('Mui-disabled')
      }
    }
  }, [isBeginning, isEnd])

  useEffect(() => handleDisableNavigation(), [isBeginning, isEnd, handleDisableNavigation])

  const getSpeed = () => {
    if (autoScroll) {
      if (reverseAutoScroll) {
        return speed === 'slower' ? 0.2 : 0.5
      }
      return speed === 'slower' ? -0.2 : -0.5
    }
    return 0.5
  }

  return (
    <CarouselWrapper sx={sx}>
      <Splide
        extensions={autoScroll ? { AutoScroll } : {}}
        ref={splide}
        options={{
          ...(vertical
            ? {
                direction: 'ttb',
                height: `${verticalHeight}px`,
                trimSpace
              }
            : {}),
          type: loop ? 'loop' : 'slide',
          focus: centerFocus || autoScroll ? 'center' : undefined,
          perPage: slidesPerView,
          perMove: slidesPerGroup,
          pagination,
          arrows: false,
          gap: spaceBetween,
          updateOnMove,
          ...(autoScroll
            ? {
                drag: 'free',
                autoScroll: {
                  autoStart: true,
                  speed: getSpeed()
                }
              }
            : {
                autoScroll: false
              }),
          padding: `${slidePadding}rem`,
          wheel,
          drag: !disableDrag,
          breakpoints,
          ...(lazyLoad
            ? {
                lazyLoad: 'nearby'
              }
            : {})
        }}
        onMoved={(e, index) => {
          setIsBeginning(index === 0)
          setIsEnd(index === childCount - slidesPerView)
        }}
        onMounted={(e) => {
          setIsBeginning(e.index === 0)
          setIsEnd(e.index === childCount - slidesPerView)
        }}
      >
        {children}
      </Splide>
      {navigationComponent ||
        (navigation && React.Children.count(children) > 1 && (
          <NavigationContainer navigationPosition={navigationPosition}>
            <CarouselNavButton refObj={prevRef} onClick={handlePrev} type="prev" />
            <CarouselNavButton refObj={nextRef} onClick={handleNext} type="next" />
          </NavigationContainer>
        ))}
    </CarouselWrapper>
  )
}

export { Carousel, type CarouselProps }
