import { marked } from 'marked'

const convertMarkdownToHtml = (markdownText: string) => {
  const renderer = new marked.Renderer()

  renderer.list = (body: string): string => `<ul style="padding-left: 15px">${body}</ul>`

  marked.setOptions({
    renderer,
    breaks: true
  })

  return marked.parse(markdownText || '')
}

export default convertMarkdownToHtml
