import React, { useMemo } from 'react'
import { useRouter } from 'next/router'
import { Box, Button, Carousel, CarouselSlide, Container, Grid, Range, Typography } from '@hermes/web-components'
import { animated, useInView } from '@react-spring/web'
import dayjs from 'dayjs'
import { SxProps } from '@mui/system'
import NextLink from 'next/link'
import { HeroContainer, HeroWrapper, TeamPhotosContainer } from './styles'
import { StrapiMedia, StrapiResponse } from '../../../types/strapi'
import StrapiImage from '../../StrapiImage'
import SearchField from '../../Search'
import ExperienceCounter, { ExperienceCounterProps } from '../../ExperienceCounter'
import markdownParser from '../../../utils/markdownParser'
import ExperienceAchievementsCounter, { ExperienceAchievementsCounterProps } from '../../ExperienceAchievementsCounter'

export interface HeroSlideProps {
  variant?: 'Default' | 'Blog' | 'CaseStudies'
  title: string
  subtitle: string
  contactButton?: string
  align?: 'left' | 'center'
  teamPhotos?: StrapiResponse<StrapiMedia[]>
  experienceCounter?: ExperienceCounterProps
  experienceAchievementsCounter?: ExperienceAchievementsCounterProps
  containerSx?: SxProps
}

const shouldDisplaySearchBox = ['Blog', 'CaseStudies']

const HeroSection = ({
  variant = 'Default',
  title,
  subtitle,
  contactButton,
  align = 'center',
  teamPhotos,
  experienceCounter,
  experienceAchievementsCounter,
  containerSx
}: HeroSlideProps) => {
  const router = useRouter()
  const teamPhotosAvailable = Boolean(teamPhotos?.data?.length)
  const backgroundPattern = align === 'left' ? 'hero-section-left' : 'hero-section'
  const [opacityRef, opacitySprings] = useInView(() => ({
    from: {
      opacity: 0
    },
    to: {
      opacity: 1
    }
  }))

  const [getInTouchAnimationRef, getInTouchAnimationSprings] = useInView(() => ({
    from: {
      opacity: 0,
      transform: 'translateY(50px)'
    },
    to: {
      opacity: 1,
      transform: 'translateY(0)'
    }
  }))

  const AnimatedTypography = animated(Typography)

  const AnimatedButton = animated(Button)

  const routeByVariant = useMemo(() => {
    switch (variant) {
      case 'Blog':
        return '/insights/page/1'
      case 'CaseStudies':
        return '/case-studies/page/1'
      default:
        return '/'
    }
  }, [variant])

  const handleOnSearch = ({ value, dateRange }: { value: string | string[]; dateRange?: Range[] }) => {
    const selectedDate = dateRange?.[0]
    const startDate = selectedDate?.startDate ? dayjs(selectedDate?.startDate).format('YYYY-MM-DD') : ''
    const endDate = selectedDate?.endDate ? dayjs(selectedDate?.endDate).format('YYYY-MM-DD') : ''

    const queries = new URLSearchParams()

    if (value) queries.set('search', String(value))
    if (startDate) queries.set('startDate', startDate)
    if (endDate) queries.set('endDate', endDate)

    router.push(`${routeByVariant}?${queries.toString()}`, '', {
      shallow: true
    })
  }

  return (
    <HeroWrapper backgroundPattern={backgroundPattern} sx={containerSx} id="home">
      <HeroContainer align={align}>
        <Grid
          container
          wrap="nowrap"
          sx={teamPhotosAvailable ? { justifyContent: 'space-between', alignItems: 'center' } : {}}
        >
          <Grid
            sx={
              teamPhotosAvailable
                ? {}
                : {
                    width: '100%'
                  }
            }
          >
            <AnimatedTypography
              variant="h1"
              className="hero-title"
              ref={opacityRef}
              style={opacitySprings}
              lineHeight="normal"
              mb="28px"
              dangerouslySetInnerHTML={{
                __html: title
              }}
            />
            <AnimatedTypography
              className="hero-subtitle"
              ref={opacityRef}
              style={opacitySprings}
              component="div"
              dangerouslySetInnerHTML={{
                __html: markdownParser(subtitle)
              }}
            />
            {variant === 'Default' && contactButton && (
              <NextLink href="/contact">
                <a>
                  <AnimatedButton
                    ref={getInTouchAnimationRef}
                    style={getInTouchAnimationSprings}
                    variant="outlined"
                    sx={{
                      backgroundColor: 'transparent',
                      padding: '18px 54px',
                      marginBottom: '30px'
                    }}
                  >
                    {contactButton}
                  </AnimatedButton>
                </a>
              </NextLink>
            )}
            {shouldDisplaySearchBox.includes(variant) && (
              <Box sx={{ maxWidth: '620px', margin: align === 'left' ? '0' : '0 auto' }}>
                <SearchField value={router.query?.search || ''} onSearch={handleOnSearch} loading={false} />
              </Box>
            )}
          </Grid>
          {Boolean(teamPhotos?.data?.length) && (
            <TeamPhotosContainer>
              <Carousel slidesPerView={1} loop autoPlay>
                {teamPhotos?.data.map((image, index) => (
                  <CarouselSlide key={`team-image-${image.id}-${index}`}>
                    <StrapiImage
                      image={{
                        data: image
                      }}
                    />
                  </CarouselSlide>
                ))}
              </Carousel>
            </TeamPhotosContainer>
          )}
        </Grid>
      </HeroContainer>
      {experienceCounter && (
        <Container>
          <ExperienceCounter {...experienceCounter} />
        </Container>
      )}
      {experienceAchievementsCounter && (
        <Container>
          <ExperienceAchievementsCounter {...experienceAchievementsCounter} />
        </Container>
      )}
    </HeroWrapper>
  )
}

export default HeroSection
