import { Container, styledComponent, styled, Grid } from '@hermes/web-components'

export const HeroWrapper = styled('section', {
  shouldForwardProp: (prop) => prop !== 'backgroundPattern'
})<{
  backgroundPattern: string
}>(({ theme, backgroundPattern }) => ({
  position: 'relative',
  backgroundImage: `url(/img/background-patterns/desktop/${backgroundPattern}.svg), ${theme.palette.additional.paperLinear}`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  [theme.breakpoints.down('sm')]: {
    backgroundImage: `url(/img/background-patterns/mobile/hero-section.svg), ${theme.palette.additional.paperLinear}`,
    backgroundPositionX: '95%'
  }
}))

export const HeroContainer = styledComponent(Container, {
  shouldForwardProp: (prop) => prop !== 'align'
})<{
  align?: 'left' | 'center'
}>(({ theme, align }) => ({
  textAlign: align || 'center',
  padding: '60px 0 30px 0',
  '.hero-subtitle': {
    maxWidth: '650px',
    margin: align === 'left' ? '0 0 40px 0' : '0 auto 40px auto'
  },
  [theme.breakpoints.down('sm')]: {
    padding: '40px 15px',
    paddingBottom: 0,
    textAlign: 'left',
    '.hero-subtitle': {
      padding: 0
    },
    '.MuiButton-root': {
      width: '100%'
    }
  }
}))

export const TeamPhotosContainer = styledComponent(Grid)(() => ({
  width: '366px',
  height: '243px',
  overflow: 'hidden',
  borderRadius: '14px',
  boxShadow: '-7px 7px 0 #769AFF',
  border: '1px solid #46BED9',
  img: {
    width: '366px',
    height: '243px',
    objectFit: 'cover'
  }
}))
