import { Grid, Typography } from '@hermes/web-components'
import useTranslateMessage from '../../hooks/useTranslateMessage'
import getColorByIndex from '../../utils/getColorByIndex'

export interface ExperienceCounterProps {
  years?: number
  projects?: number
  clients?: number
  countries?: number
}

const ExperienceCounter = (counts: ExperienceCounterProps) => {
  const t = useTranslateMessage()
  const countersList = Object.entries(counts).map(([key, value]) => ({
    id: key,
    count: value,
    countTitle: key
  }))

  return (
    <Grid container justifyContent="space-between" gap="40px" width="100%" m="0 0 30px 0">
      {countersList.map((counter, index) => (
        <Typography textAlign="center" key={`exp-count-${index}`} color={getColorByIndex(index)}>
          <Typography component="span" variant="h1" fontSize="48px">
            {counter.count}+
          </Typography>
          <br />
          <Typography component="span" textTransform="uppercase">
            {t(counter.countTitle)}
          </Typography>
        </Typography>
      ))}
    </Grid>
  )
}

export default ExperienceCounter
