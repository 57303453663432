import React, { useState, useRef, useEffect, useMemo } from 'react'
import { SxProps } from '@mui/material'
import type { ImgHTMLAttributes } from 'react'
import { InView } from 'react-intersection-observer'
import { Box } from '../Box'
import { styled } from '../../styles/styled'

export type ImageProps = ImgHTMLAttributes<HTMLImageElement> & {
  lazy?: boolean
  lazyOffset?: number
  errorSrc?: string
  height?: string | number
  width?: string | number
  src: string
  alt: string
  className?: string
  sx?: SxProps
}

const StyledImage = styled('img', {
  shouldForwardProp: (prop: string) => prop !== 'sx'
})(({ sx }) => ({
  ...sx
}))

function Image({
  lazy,
  src,
  errorSrc,
  lazyOffset,
  alt,
  height,
  width,
  className,
  sx = {},
  ...imageAttributes
}: ImageProps) {
  const [primaryImageUrlNotLoaded, setPrimaryImageUrlNotLoaded] = useState(false)
  const imageRef = useRef<HTMLImageElement>(null)

  useEffect(() => {
    const image = imageRef.current
    if (image && image.complete && image.naturalWidth === 0) {
      setPrimaryImageUrlNotLoaded(true)
    }
  }, [])

  const onLoadImageError = () => setPrimaryImageUrlNotLoaded(true)

  const srcToShow = useMemo(() => {
    if (primaryImageUrlNotLoaded && errorSrc) return errorSrc

    return src
  }, [primaryImageUrlNotLoaded, errorSrc, src])

  if (!src) return null

  return (
    <InView rootMargin={`${lazyOffset}px`} triggerOnce skip={!lazy} initialInView={!lazy}>
      {({ inView, ref }) => (
        <Box ref={ref}>
          {inView && (
            <StyledImage
              {...imageAttributes}
              sx={sx}
              className={className}
              ref={imageRef}
              src={srcToShow}
              key={srcToShow}
              alt={alt}
              loading={lazy ? 'lazy' : undefined}
              width={width}
              height={height}
              onError={onLoadImageError}
            />
          )}
        </Box>
      )}
    </InView>
  )
}

Image.defaultProps = {
  lazy: false,
  lazyOffset: 100,
  errorSrc: '',
  height: undefined,
  width: undefined
}

export default Image
