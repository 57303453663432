import { styledComponent } from '../../styles/styled'
import { Button } from '../Button'
import { Box } from '../Box'

export const CarouselWrapper = styledComponent(Box)({
  position: 'relative'
})

export const NavigationContainer = styledComponent(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'bottom' && prop !== 'laterally' && prop !== 'inside' && prop !== 'navigationPosition'
})<{ navigationPosition: 'bottom' | 'laterally' | 'inside' }>(({ navigationPosition, theme }) => ({
  ...(navigationPosition === 'bottom' && {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    gap: '10px'
  }),
  zIndex: 3,
  ...(navigationPosition === 'laterally' && {
    button: {
      position: 'absolute',
      zIndex: 500,
      top: 'calc(50% - 20px)',
      '&:first-of-type': {
        left: '-25px'
      },
      '&:last-of-type': {
        right: '-25px'
      }
    }
  }),
  ...(navigationPosition === 'inside' && {
    position: 'absolute',
    display: 'flex',
    marginTop: '40px',
    width: '100%',
    justifyContent: 'center',
    gap: '10px',
    bottom: '50px',
    zIndex: 1
  }),
  [theme.breakpoints.down('sm')]: {
    ...(navigationPosition === 'laterally' && {
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      justifyContent: 'center',
      gap: '25px',
      marginTop: '35px',
      button: {
        left: '0 !important',
        right: '0 !important',
        position: 'relative'
      }
    })
  }
}))

export const NavButton = styledComponent(Button)(({ theme }) => ({
  width: '50px',
  height: '50px',
  borderRadius: '50%',
  padding: 0,
  minWidth: 'auto',
  border: `0.2px solid ${theme.palette.additional.border}`,
  backgroundColor: theme.palette.background.paper,
  transition: '0.2s',
  '&.Mui-disabled': {
    pointerEvents: 'auto !important',
    'svg > path': {
      fill: '#D9D9D9'
    },
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
      'svg > path': {
        fill: '#D9D9D9'
      }
    }
  },
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    'svg > path': {
      fill: theme.palette.typography.main
    }
  },
  [theme.breakpoints.down('sm')]: {
    width: '40px',
    height: '40px'
  }
}))

export const StyledGridSlide = styledComponent(Box)({
  display: 'grid',
  gap: '45px',
  width: 'inherit',
  minHeight: 'max-content',
  '&>div': {
    width: 'inherit'
  }
})
