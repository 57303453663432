import React, { SVGProps } from 'react'

const ChevronRight = (props: SVGProps<any>) => (
  <svg viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M1.07028 11.314L6.72728 5.657L1.07028 0L0.363281 0.707L5.31328 5.657L0.363281 10.607L1.07028 11.314Z"
      fill="black"
    />
  </svg>
)

export default ChevronRight
