import Head from 'next/head'
import { StrapiMedia, StrapiResponse } from '../../types/strapi'

export interface PageMetadataProps {
  title: string
  ogTitle: string
  twitterTitle: string
  description: string
  keywords: string
  ogType: string
  ogImage: StrapiResponse<StrapiMedia>
  twitterImage: StrapiResponse<StrapiMedia>
  twitterCard: string
  blockIndexing: boolean
}

const PageMetadata = ({
  title,
  ogTitle,
  twitterTitle,
  description,
  keywords,
  ogType,
  ogImage,
  twitterImage,
  twitterCard,
  blockIndexing
}: PageMetadataProps) => (
  <>
    <Head>
      <title>{title}</title>
      <meta name="version" content={process.env.NEXT_PUBLIC_APP_VERSION} />
      <meta property="og:title" content={ogTitle || ogTitle} />
      <meta name="twitter:title" content={twitterTitle || twitterTitle} />
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      <meta name="description" property="og:description" content={description} />
      <meta name="twitter:description" content={description} />
      <meta property="og:description" content={description} />
      {keywords && <meta name="keywords" content={keywords} />}
      {/* {updatedAt && <meta name="publish_date" property="og:publish_date" content={updatedAt} />} */}
      {/* {canonicalUrl && !blockIndexing && ( */}
      {/*    <> */}
      {/*      <link rel="canonical" href={canonicalUrl} /> */}
      {/*      <meta property="og:url" content={canonicalUrl} /> */}
      {/*    </> */}
      {/* )} */}
      <meta property="og:type" content={ogType || 'website'} />
      <meta name="twitter:card" content={twitterCard || 'summary_large_image'} />
      <meta name="twitter:site" content="@uniformdev" />
      <meta property="og:site_name" content="Uniform.dev" />
      {ogImage?.data?.attributes?.url && <meta property="og:image" content={ogImage.data.attributes.url} />}
      {twitterImage?.data?.attributes?.url && <meta name="twitter:image" content={twitterImage.data.attributes.url} />}
      {/* {children} */}
      {/* {Boolean(pageJsonLd) && ( */}
      {/*    <Script */}
      {/*        id="structured-data" */}
      {/*        type="application/ld+json" */}
      {/*        dangerouslySetInnerHTML={{ __html: pageJsonLd }} */}
      {/*        key="product-jsonld" */}
      {/*    /> */}
      {/* )} */}
      {blockIndexing && <meta name="robots" content="noindex" />}
    </Head>
  </>
)

export default PageMetadata
