import { CarouselLazyImage, Image } from '@hermes/web-components'
import { CSSProperties } from 'react'
import { StrapiMedia, StrapiResponse } from '../../types/strapi'

interface StrapiImageProps {
  image?: StrapiResponse<StrapiMedia>
  alt?: string
  lazy?: 'carousel' | 'offset'
  sx?: CSSProperties
  width?: string | number
  height?: string | number
  lazyOffset?: number
}

const StrapiImage = (props: StrapiImageProps) => {
  const imageAttributes = props.image?.data?.attributes
  const imageFormats = imageAttributes?.formats
  const formatsFromSmallerToLarger =
    imageFormats?.small || imageFormats?.medium || imageFormats?.large || imageAttributes

  return props.lazy === 'carousel' ? (
    <CarouselLazyImage
      style={props.sx}
      src={formatsFromSmallerToLarger?.url || '/'}
      alt={props.alt || imageAttributes?.name || ''}
      width={props.width || formatsFromSmallerToLarger?.width}
      height={props.height || formatsFromSmallerToLarger?.height}
    />
  ) : (
    <Image
      src={formatsFromSmallerToLarger?.url || '/'}
      sx={props.sx}
      alt={props.alt || imageAttributes?.name || ''}
      width={props.width || formatsFromSmallerToLarger?.width}
      height={props.height || formatsFromSmallerToLarger?.height}
      lazyOffset={props.lazyOffset}
    />
  )
}

export default StrapiImage
