import { Box, styled } from '@hermes/web-components'

export const ExperienceAchievementsCounterItemContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'color'
})<{
  color: string
}>(({ theme, color }) => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  color,
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px'
  }
}))
export const ExperienceAchievementsCounterContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '72px',
  flexWrap: 'wrap',
  paddingBottom: '30px',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: '26px'
  }
}))
