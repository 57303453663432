import { Button, CircularProgress, Range, useClickOutside, useTheme } from '@hermes/web-components'
import SearchIcon from '@hermes/web-components/src/icons/Search'
import CalendarIcon from '@hermes/web-components/src/icons/Calendar'
import { ChangeEvent, useRef, useState } from 'react'
import dynamic from 'next/dynamic'
import { ChooseDateDropdownWrapper, SearchField as StyledSearchField, SearchItemsContainer } from './styles'
import useTranslateMessage from '../../hooks/useTranslateMessage'

const DateRange = dynamic(() => import('../DateRange'), { ssr: false })

interface SearchFieldProps {
  value: string | string[]
  onSearch: ({ value, dateRange }: { value: string | string[]; dateRange?: Range[] }) => void
  loading?: boolean
}

const SearchField = ({ value = '', onSearch, loading = false }: SearchFieldProps) => {
  const [search, setSearch] = useState(value)
  const [dateRangeDropdownOpen, setDateRangeDropdownOpen] = useState(false)
  const theme = useTheme()
  const t = useTranslateMessage()
  const searchInputWrapperRef = useRef<HTMLDivElement | null>(null)
  const [dateRange, setDateRange] = useState<Range[]>([
    {
      startDate: undefined,
      endDate: undefined,
      key: 'selection'
    }
  ])
  const handleOpenDateRange = () => {
    setDateRangeDropdownOpen(!dateRangeDropdownOpen)
  }
  useClickOutside(searchInputWrapperRef, () => setDateRangeDropdownOpen(false))

  const handleClickSearch = () => onSearch({ value: search, dateRange })
  const handleClickSearchWithoutDateRange = () => {
    onSearch({ value: search })
    setDateRange([
      {
        startDate: undefined,
        endDate: undefined,
        key: 'selection'
      }
    ])
    setDateRangeDropdownOpen(false)
  }
  const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => setSearch(event.target.value)

  return (
    <SearchItemsContainer>
      <StyledSearchField
        ref={searchInputWrapperRef}
        InputProps={{
          startAdornment: <SearchIcon />,
          onKeyDown: (e) => {
            if (e.key === 'Enter') handleClickSearch()
          },
          endAdornment: (
            <>
              <Button variant="ghost" aria-label="calendar-button" disableRipple onClick={handleOpenDateRange}>
                <CalendarIcon
                  fill={dateRangeDropdownOpen ? theme.palette.primary.main : theme.palette.typography.secondary}
                />
              </Button>
              {dateRangeDropdownOpen && (
                <ChooseDateDropdownWrapper>
                  <DateRange
                    rangeColors={[theme.palette.primary.main]}
                    onChange={(item) => setDateRange([item.selection])}
                    moveRangeOnFirstSelection={false}
                    ranges={dateRange}
                    showDateDisplay={false}
                  />
                  <Button
                    variant="ghost"
                    disableRipple
                    onClick={handleClickSearchWithoutDateRange}
                    sx={{
                      color: 'primary.main',
                      paddingBottom: '7px',
                      textTransform: 'none'
                    }}
                  >
                    {t('chooseAll')}
                  </Button>
                </ChooseDateDropdownWrapper>
              )}
            </>
          )
        }}
        value={search}
        onChange={handleChangeSearch}
        placeholder="Search"
        variant="outlined"
      />
      <Button onClick={handleClickSearch} variant="outlined" sx={{ height: '55px', backgroundColor: 'transparent' }}>
        {loading ? <CircularProgress size="32px" /> : 'Search'}
      </Button>
    </SearchItemsContainer>
  )
}

export default SearchField
