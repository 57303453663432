import { Box, Typography } from '@hermes/web-components'
import NextImage from 'next/image'
import { StrapiMedia, StrapiResponse } from '../../types/strapi'
import useTranslateMessage from '../../hooks/useTranslateMessage'
import { ExperienceAchievementsCounterContainer, ExperienceAchievementsCounterItemContainer } from './styles'

export interface ExperienceAchievementsCounterProps {
  years?: number
  peopleInTeam?: number
  achievementsLogos?: StrapiResponse<StrapiMedia[]>
}

const ExperienceCounterItem = ({ count, color, countTitle }: { count: number; color: string; countTitle: string }) => (
  <ExperienceAchievementsCounterItemContainer color={color}>
    <Typography
      component="span"
      variant="h1"
      fontSize="48px"
      sx={(theme) => ({
        [theme.breakpoints.down('sm')]: {
          fontSize: '24px'
        }
      })}
    >
      {count}+
    </Typography>
    <Typography
      component="span"
      textTransform="uppercase"
      sx={(theme) => ({
        [theme.breakpoints.down('sm')]: {
          fontSize: '24px'
        }
      })}
    >
      {countTitle}
    </Typography>
  </ExperienceAchievementsCounterItemContainer>
)

const ExperienceAchievementsCounter = ({
  years,
  peopleInTeam,
  achievementsLogos
}: ExperienceAchievementsCounterProps) => {
  const t = useTranslateMessage()

  return (
    <ExperienceAchievementsCounterContainer>
      {!!years && <ExperienceCounterItem count={years} color="#769AFF" countTitle={t('years')} />}
      {!!peopleInTeam && <ExperienceCounterItem count={peopleInTeam} color="#42A6CB" countTitle={t('peopleInTeam')} />}
      {achievementsLogos &&
        achievementsLogos.data.map((achievement) => (
          <Box
            sx={{
              '& .MuiBox-root': {
                width: '120px',
                height: '60px'
              }
            }}
            key={achievement.id}
          >
            <NextImage width="120" height="60" src={achievement.attributes.url} alt={achievement.attributes.name} />
          </Box>
        ))}
    </ExperienceAchievementsCounterContainer>
  )
}

export default ExperienceAchievementsCounter
