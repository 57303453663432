import { Box, Paper, styledComponent, TextField } from '@hermes/web-components'

export const SearchField = styledComponent(TextField)(({ theme }) => ({
  '.MuiInputBase-root': {
    border: `1px solid ${theme.palette.typography.primary}`,
    padding: '15px 25px',
    backgroundColor: 'transparent',
    gap: '7px',
    input: {
      fontSize: '16px',
      '&::placeholder': {}
    }
  }
}))

export const ChooseDateDropdownWrapper = styledComponent(Paper)(({ theme }) => ({
  position: 'absolute',
  zIndex: 2,
  padding: '20px',
  borderRadius: '20px',
  backgroundColor: theme.palette.background.default,
  boxShadow: '0px 0px 15px 1px #D1D9E6',
  top: 'calc(100% + 10px)',
  width: '100%',
  left: 0,
  animation: 'fade 0.1s',
  display: 'grid',
  justifyContent: 'center',
  '@keyframes fade': {
    '0%': { transform: 'translateY(25px)', opacity: 0 },
    '100%': { transform: 'translateY(0)', opacity: 1 }
  },
  [theme.breakpoints.down('sm')]: {
    top: 'calc(100% + 90px)'
  }
}))

export const SearchItemsContainer = styledComponent(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '2fr 1fr',
  gap: '20px',
  [theme.breakpoints.down('lg')]: {
    maxWidth: '80%'
  },
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%'
  },
  [theme.breakpoints.down('sm')]: {
    display: 'grid',
    gridTemplateColumns: 'none',
    gridTemplateRows: '1fr 1fr'
  }
}))
